<template>
  <div>
    <v-app-bar flat app style="background-color: #fff; border-bottom: 1px solid #ddd">
      <div
        style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        "
      >
        <v-app-bar-nav-icon :style="{ color: $colors[0] }" @click="drawer = !drawer">
        </v-app-bar-nav-icon>
        <div style="display: flex; justify-content: space-between; align-items: center">
          <!-- user info button -->
          <div class="dropdown-user-info">
            <div class="dropdown-user-info-toggler">
              <div style="display: flex; flex-direction: column">
                <span style="font-size: 12px; font-weight: 600; text-align: right">{{
                  userName
                }}</span>
                <span style="font-size: 12px; font-weight: 400; text-align: right">{{
                  userRole
                }}</span>
              </div>
              <div>
                <v-avatar class="ml-4" size="40" :color="$colors[0]">
                  <v-icon dark> mdi-account-badge-outline </v-icon>
                </v-avatar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-app-bar>
    <v-navigation-drawer v-model="drawer" flat app>
      <router-link to="/" class="d-flex flex-row justify-center pa-4">
        <v-img
          alt="SMART-ELEC Logo"
          class="shrink ma-2 text-center"
          contain
          src="../../assets/logoSmart.png"
          transition="scale-transition"
          width="450"
          height="129"
        />
      </router-link>
      <v-list nav dense class="pa-0 mt-5">
        <v-list-item-group class="px-0">
          <v-list-item
            class="pa-0 buttonStyle"
            v-for="(route, index) in filteredRoutes"
            v-if="route.name !== 'Login'"
            :key="index"
          >
            <!-- side bar item without sub routes -->
            <v-col class="pa-0 hover" v-if="!route.children">
              <router-link
                :style="[
                  $route.name === route.name
                    ? { 'background-color': 'rgba(1, 87, 155, 0.8)' }
                    : {},
                ]"
                :to="`${route.path}`"
                class="d-flex flex-row link buttonStyle"
              >
                <v-list-item-icon class="pl-2">
                  <v-icon
                    :style="[
                      $route.name === route.name
                        ? { color: 'white' }
                        : { color: '#01579B' },
                    ]"
                    class="link-icon"
                    >{{ route?.meta.icon }}</v-icon
                  >
                </v-list-item-icon>
                <v-list-item-title
                  :style="[$route.name === route.name ? { color: 'white' } : {}]"
                  class="link-text Poppins-Bold"
                  >{{ route?.name }}</v-list-item-title
                >
              </router-link>
            </v-col>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <template v-slot:append>
        <div class="mt-10" style="width: 100%; background-color: #01579b">
          <div class="d-flex justify-content-center" app>
            <v-btn plain class="text-white" elevation="0" depressed @click="logout">
              <span class="Poppins-Bold">Déconnexion</span>
              <v-icon class="mx-2">mdi-logout-variant</v-icon>
            </v-btn>
          </div>
        </div>
      </template>
    </v-navigation-drawer>
  </div>
</template>
<script>
export default {
  name: "Drawer",
  components: {},
  data() {
    return {
      drawer: true,
      userName: "",
      userRole: "",
    };
  },
  created() {
    this.userName = localStorage.getItem("email");
    this.userRole = localStorage.getItem("typeUser");
  },
  computed: {
    filteredRoutes() {
      const filtered = this.$router.options.routes.filter((route) => {
        if (route?.meta?.roles?.length > 0) {
          return route?.meta?.roles?.includes("admin") && !route?.meta?.hidden;
        } else {
          return true;
        }
      });
      return filtered;
    },
  },
  methods: {
    logout() {
      this.$store.state.isAuthenticated=false
      localStorage.clear();
      localStorage.removeItem('token')
      this.$router.push("/login");
    },
    navigateTo(routePath) {
      const currentRoutePath = this.$router.currentRoute.path;
      if (currentRoutePath !== routePath) {
        this.$router.push(routePath);
      }
    },
  },
};
</script>
<style scoped>
::v-deep .buttonStyle:focus,
.buttonStyle:hover {
  background-color: rgba(1, 87, 155, 1) !important;
  color: white !important;
}
.buttonStyle:hover .link-icon,
.buttonStyle:hover .link-text {
  color: white !important;
}
a,
ul {
  text-decoration: none !important;
}
/****************************** */
/* user info dropdown */
/****************************** */
.dropdown-user-info {
  display: inline-block;
  padding: 8px;
}

.dropdown-user-info-toggler {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  color: #000;
  user-select: none;
}

.user-info-circle {
  width: 40px;
  height: 40px;
  background-color: #bbcfce;
  border-radius: 50%;
  margin-left: 12px;
  overflow: hidden;
  position: relative;
}
.user-info-circle-image {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
}
.devider {
  width: 100%;
  height: 1px;
  background-color: #e2e2e2;
}
</style>
