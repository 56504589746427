import Vue from 'vue'
import VueRouter from 'vue-router'
import CotationsView from '../views/CotationsView.vue'
import VentesView from "@/views/VentesView.vue"
import ComptesView from "@/views/ComptesView.vue"
import LoginView from "@/views/login/login.vue"
import store from "../store"
Vue.use(VueRouter)

/*
  name:'router-name'             the name field is required when using <keep-alive>, it should also match its component's name property
                                 detail see : https://vuejs.org/v2/guide/components-dynamic-async.html#keep-alive-with-Dynamic-Components
  redirect:                      if set to 'noredirect', no redirect action will be trigger when clicking the breadcrumb
  meta: {
    roles: ['admin', 'editor']   will control the page roles (allow setting multiple roles)
    title: 'title'               the name showed in subMenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon showed in the sidebar
    hidden: true                 if true, this route will not show in the sidebar (default is false)
    alwaysShow: true             if true, will always show the root menu (default is false)
                                 if false, hide the root menu when has less or equal than one children route
    breadcrumb: false            if false, the item will be hidden in breadcrumb (default is true)
    noCache: true                if true, the page will not be cached (default is false)
    affix: true                  if true, the tag will affix in the tags-view
    activeMenu: '/example/list'  if set path, the sidebar will highlight the path you set
  }
*/

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: LoginView,
    meta: {
      hidden: true,
      requiresAuth:false,
    }
  },
  {
    path: '/cotations',
    alias:"/",
    name: 'Cotations',
    meta: {
      requiresAuth: true,
      roles: ["admin", "superadmin"],
      icon: "mdi-cash-multiple",
    },
    component: CotationsView
  },
  {
    path: '/ventes',
    name: 'Ventes',
    meta: {
      requiresAuth: true,
      roles: ["admin", "superadmin"],
      icon: "mdi-shopping-outline",
    },
    component: VentesView
  },
  {
    path: '/comptes',
    name: 'Comptes',
    meta: {
      requiresAuth: true,
      roles: ["admin", "superadmin"],
      icon: "mdi-account-group-outline",
    },
    component: ComptesView
  },
  
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
});

router.beforeEach((to, from, next) => {
  // check user authenticated or not
  const token = localStorage.getItem('token');
  const userRole = 'admin';
  const isAuthenticated = token ? true : false;
  // check if the entered route require authentication
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiredRoles = to.meta.roles;

  if (isAuthenticated && userRole) {
      if (to.path === '/login') {
          next('/cotations');
      } else if (requiresAuth && (!requiredRoles || requiredRoles.includes(userRole))) {
          next(); // Allow access to the route
      } else {
          next('/cotations');
      } 
  } else if (requiresAuth && (!isAuthenticated || !userRole)) {
      // If the route requires authentication and the user is not authenticated,
      next('/login');
  } else {
      // For all other cases (non-protected routes), proceed to the next route.
      next();
  }
});

export default router;

