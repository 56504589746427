<template>
  <v-app>
    <Drawer v-if="tokenUser!=null"/>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Drawer from "./components/drawer/Drawer";
export default {
  name: "App",
  components: {
    Drawer,
  },
  data: () => ({
    tokenUser: null,
  }),
  async created() {
    this.tokenUser = this.$getToken ('token')
    await this.verifyToken()
    this.test()
  },
  methods: {
    test(){
      let self = this
      var removeItem = localStorage.removeItem
      localStorage.removeItem = function(key) {
        var event = new Event('removeItem');
        document.dispatchEvent(event);
        removeItem.apply(this, arguments);
        
        if(key != 'token'){
          removeItem.apply(this, arguments);
        }else{
          removeItem.apply(this, arguments);
          self.tokenUser = localStorage.getItem('token')
        }
      }
      var localStorageRemoveHandler = function(e) {
        var x = 0
      }
      document.addEventListener("removeItem", localStorageRemoveHandler, false);
      var originalSetItem = localStorage.setItem;
      localStorage.setItem = function(key, value) {
        self.tokenUser = localStorage.getItem('token')
        var event = new Event('itemInserted');
        event.value = value; // Optional..
        event.key = key; // Optional..
        document.dispatchEvent(event)
        if(key != 'token'){
          originalSetItem.apply(this, arguments)
        }else{
          originalSetItem.apply(this, arguments)
          self.tokenUser = localStorage.getItem('token')
        }
      };

      var localStorageSetHandler = function(e) {
        var x = 0
      }

      document.addEventListener("itemInserted", localStorageSetHandler, false);
    },
    async verifyToken(){
      let self = this
      window.addEventListener('storage', async () => {
        console.log('**************')
        self.tokenUser = await localStorage.getItem('token')
        console.log(self.tokenUser)
        if(self.tokenUser == null){
          self.$router.push('/login')
        }
      });
    }
  }
};
</script>

<style>
@import "./css/base.css";
@font-face {
  font-family: "Poppins-Regular";
  src: url("assets/fonts/Poppins-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Poppins-Bold";
  src: url("assets/fonts/Poppins-Bold.ttf") format("truetype");
}
.Poppins-Bold {
  font-family: "Poppins-Bold";
}
.Poppins-Regular {
  font-family: "Poppins-Regular";
}
* {
  font-family: "Poppins-Regular";
}
</style>
