import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isAuthenticated:false,
  },
  getters: {
    gettersAuthenticated(state){
      return state.isAuthenticated
    }
  },
  mutations: {
    changeAuthenticated(state){
      state.isAuthenticated = !state.isAuthenticated
    }
  },
  actions: {
    changeAuthenticatedAction(context){
      context.commit('changeAuthenticated')
    }
  },
  modules: {
  },
  plugins:[new VuexPersistence().plugin]
})
